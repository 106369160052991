import { StepDefinition } from '../../types';

export default {
  key: 'send_call',
  name: 'Call Number',
  description: 'Call a phone number and have a conversation with an AI agent. ',
  icon: 'i-respell-bland',
  //documentationLink: 'https://docs.respell.ai/bland',
  category: 'integrations',
  services: ['bland'],
  groups: {
    basic: {
      key: 'basic',
      name: 'Basic',
    },
    agent: {
      key: 'agent',
      name: 'Agent Settings',
    },
    phone: {
      key: 'phone',
      name: 'Phone Settings',
    },
    conversation: {
      key: 'conversation',
      name: 'Conversation Settings',
    },
    advanced: {
      key: 'advanced',
      name: 'Advanced',
    },
  },
  options: {
    phone_number: {
      type: 'text/phone-number',
      isOptional: false,
      description:
        'The phone number to call. Country code defaults to +1 (US) if not specified. Formatting is flexible, however for the most predictable results use the E.164 format.',
      name: 'Phone Number',
      listDepth: 0,
      value: null,
      key: 'phone_number',
      metadata: {
        group: 'basic',
      },
    },
    task: {
      type: 'text/plain',
      isOptional: false,
      description:
        'Provide instructions, relevant information, and examples of the ideal conversation flow.',
      name: 'Task',
      listDepth: 0,
      value: null,
      // conditions: [
      //   {
      //     reference: 'pathway_id',
      //     type: 'equals',
      //     value: null,
      //   },
      // ],
      key: 'task',
      metadata: {
        group: 'basic',
      },
    },
    voice: {
      type: 'text/plain',
      isOptional: true,
      description:
        'The voice of the AI agent to use. Accepts any form of voice ID, including custom voice clones and voice presets. Default voices can be referenced directly by their name instead of an id.',
      name: 'Voice',
      listDepth: 0,
      metadata: {
        group: 'agent',
        options: {
          reference: {
            namespace: 'bland',
            member: 'list_voices',
          },
        },
      },
      value: 'mason',
      key: 'voice',
    },
    first_sentence: {
      type: 'text/plain',
      isOptional: true,
      description:
        'A phrase that your call will start with instead of a generating one on the fly. This works both with and without wait_for_greeting. Can be more than one sentence, but must be less than 200 characters.',
      name: 'First Sentence',
      listDepth: 0,
      value: null,
      key: 'first_sentence',
      metadata: {
        group: 'conversation',
      },
    },
    wait_for_greeting: {
      type: 'boolean',
      isOptional: true,
      description:
        'Should the AI speak first or wait for someone else to talk?',
      name: 'Wait for Greeting',
      listDepth: 0,
      value: false,
      key: 'wait_for_greeting',
      metadata: {
        group: 'conversation',
      },
    },
    interruption_threshold: {
      type: 'number/integer',
      isOptional: true,
      description:
        'When you increase the interruption latency, you force the AI phone agent to listen longer before responding. In practice, increasing the threshold results in less interruptions and more latency.',
      name: 'Interruption Threshold',
      metadata: {
        group: 'agent',
        min: 0,
        max: 500,
      },
      listDepth: 0,
      value: 50,
      key: 'interruption_threshold',
    },
    pathway_id: {
      type: 'text/plain',
      isOptional: true,
      description:
        'This is the pathway ID for the pathway you have created on our dev portal. You can access the ID of your pathways by clicking the ‘Copy ID’ button of your pathway.',
      name: 'Pathway ID',
      listDepth: 0,
      value: null,
      key: 'pathway_id',
      metadata: {
        group: 'conversation',
      },
    },
    model: {
      type: 'text/plain',
      key: 'model',
      isOptional: true,
      description:
        'Select a model to use for your call. Options: gpt4, base, turbo and enhanced.',
      name: 'Model',
      listDepth: 0,
      value: 'enhanced',
      conditions: [
        {
          reference: 'pathway_id',
          type: 'equals',
          value: null,
        },
      ],
      metadata: {
        group: 'agent',
        options: [
          {
            id: 'base',
            key: 'base',
            name: 'Base',
          },
          {
            id: 'turbo',
            key: 'turbo',
            name: 'Turbo',
          },
          {
            id: 'enhanced',
            key: 'enhanced',
            name: 'Enhanced',
          },
        ],
      },
    },
    record: {
      type: 'boolean',
      isOptional: true,
      description:
        'To record your phone call, set record to true. When your call completes, you can access through the recording_url field in the call details or your webhook.',
      name: 'Record',
      listDepth: 0,
      value: false,
      key: 'record',
      metadata: {
        group: 'phone',
      },
    },
    request_data: {
      type: 'object',
      isOptional: true,
      description:
        'A JSON object that will be sent to bland when the call is answered.',
      name: 'Request Data',
      metadata: {
        group: 'advanced',
        schema: {
          key: {
            type: 'text/plain',
            isOptional: false,
            description: 'The key of the data you want to send.',
            name: 'Key',
            listDepth: 0,
            value: null,
            key: 'key',
          },
          value: {
            type: 'text/plain',
            isOptional: false,
            description: 'The value of the data you want to send.',
            name: 'Value',
            listDepth: 0,
            value: null,
            key: 'value',
          },
        },
      },
      listDepth: 1,
      value: null,
      key: 'request_data',
    },
    transfer_phone_number: {
      type: 'text/plain',
      isOptional: true,
      description:
        'A phone number that the agent can transfer to under specific conditions - such as being asked to speak to a human or supervisor.',
      name: 'Transfer Phone Number',
      conditions: [
        {
          reference: 'pathway_id',
          type: 'equals',
          value: null,
        },
      ],
      listDepth: 0,
      value: null,
      key: 'transfer_phone_number',
      metadata: {
        group: 'conversation',
      },
    },
    // transfer_list: {
    //   type: 'object',
    //   isOptional: true,
    //   description:
    //     'Give your agent the ability to transfer calls to a set of phone numbers. Overrides transfer_phone_number if a transfer_list.default is specified. Will default to transfer_list.default, or the chosen phone number.',
    //   name: 'Transfer List',
    //   metadata: {
    //     schema: {
    //       transfer_phone_number: {
    //         type: 'text/plain',
    //         isOptional: false,
    //         description: 'A phone number that the agent can transfer to.',
    //         name: 'Transfer Phone Number',
    //         listDepth: 0,
    //         value: null,
    //         key: 'transfer_phone_number',
    //       },
    //       name_of_transfer: {
    //         type: 'text/plain',
    //         isOptional: false,
    //         description: 'The name of the transfer.',
    //         name: 'Name of Transfer',
    //         listDepth: 0,
    //         value: null,
    //         key: 'name_of_transfer',
    //       },
    //     },
    //   },
    //   conditions: [
    //     {
    //       reference: 'pathway_id',
    //       type: 'equals',
    //       value: null,
    //     },
    //   ],
    //   listDepth: 1,
    //   value: [{}],
    //   key: 'transfer_list',
    // },
    language: {
      type: 'text/plain',
      isOptional: true,
      description:
        'Select a supported language of your choice. Optimizes every part of our API for that language - transcription, speech, and other inner workings.',
      name: 'Language',
      listDepth: 0,
      value: 'ENG',
      metadata: {
        group: 'agent',
        options: [
          {
            id: 'ENG',
            key: 'ENG',
            name: 'English',
          },
          {
            id: 'ESP',
            key: 'ESP',
            name: 'Spanish',
          },
          {
            id: 'FRE',
            key: 'FRE',
            name: 'French',
          },
          {
            id: 'GER',
            key: 'GER',
            name: 'German',
          },
        ],
      },
      key: 'language',
    },
    max_duration: {
      type: 'number/decimal',
      isOptional: true,
      description:
        'Set the longest you want the call to possibly go in minutes. After the max_duration minutes have passed, the call will automatically end.',
      name: 'Max Duration',
      listDepth: 0,
      metadata: {
        group: 'phone',
        min: 1,
        max: 60,
      },
      value: 30,
      key: 'max_duration',
    },
    answered_by_enabled: {
      type: 'boolean',
      isOptional: true,
      description:
        'Enables machine detection when the call starts to determine whether the call was answered by a person or a voicemail.',
      name: 'Answered by Enabled',
      listDepth: 0,
      value: true,
      key: 'answered_by_enabled',
      metadata: {
        group: 'phone',
      },
    },
    from: {
      type: 'text/plain',
      isOptional: true,
      description:
        'Specify a purchased Outbound Number to call from. Country code is required, spaces or parentheses must be excluded.',
      name: 'From',
      metadata: {
        group: 'phone',
        allowsCustom: true,
        options: {
          reference: {
            namespace: 'bland',
            member: 'list_inbound',
            arguments: [
              {
                namespace: 'options',
                member: 'custom_twillio_key',
                parameter: 'encrypted_key',
              },
            ],
          },
        },
      },
      listDepth: 0,
      value: null,
      key: 'from',
    },

    temperature: {
      type: 'number/decimal',
      isOptional: true,
      description:
        'A value between 0 and 1 that controls the randomness of the LLM. 0 will cause more deterministic outputs while 1 will cause more random.',
      name: 'Temperature',
      listDepth: 0,
      value: 0.7,
      metadata: {
        group: 'agent',
        min: 0,
        max: 1,
      },
      key: 'temperature',
    },
    start_time: {
      type: 'datetime',
      isOptional: true,
      description:
        'The time you want the call to start. If you don’t specify a time (or the time is in the past), the call will send immediately.',
      name: 'Start Time',
      listDepth: 0,
      value: null,
      key: 'start_time',
      metadata: {
        group: 'advanced',
      },
    },
    voicemail_message: {
      type: 'text/plain',
      isOptional: true,
      description:
        'When the AI encounters a voicemail, it will leave this message after the beep and then immediately end the call.',
      name: 'Voicemail Message',
      listDepth: 0,
      value: null,
      key: 'voicemail_message',
      metadata: {
        group: 'phone',
      },
    },
    voicemail_action: {
      type: 'text/plain',
      isOptional: true,
      description:
        'This is processed separately from the AI’s decision making, and overrides it.',
      name: 'Voicemail Action',
      listDepth: 0,
      value: 'hangup',
      metadata: {
        group: 'phone',
        options: [
          {
            id: 'hangup',
            key: 'hangup',
            name: 'Hangup',
          },
          {
            id: 'ignore',
            key: 'ignore',
            name: 'Ignore',
          },
          {
            id: 'leave_message',
            key: 'leave_message',
            name: 'Leave Message',
          },
        ],
      },
      key: 'voicemail_action',
    },
    amd: {
      type: 'boolean',
      isOptional: true,
      description:
        'AMD mode helps your AI navigate phone trees and IVR systems. If you know your call will hit an automated system you should switch it on.',
      name: 'AMD',
      listDepth: 0,
      value: false,
      key: 'amd',
      metadata: {
        group: 'phone',
      },
    },
    custom_twillio_key: {
      type: 'text/plain',
      isOptional: true,
      description:
        'If you are using BYOT, you can add your encrypted key here.',
      name: 'Twillio Encrypted Key',
      listDepth: 0,
      value: null,
      key: 'custom_twillio_key',
      metadata: {
        group: 'advanced',
      },
    },
  },
  outputs: {
    transcript: {
      type: 'text/plain',
      description: 'The transcript of the call.',
      name: 'Transcript',
      isOptional: false,
      listDepth: 0,
      value: null,
      key: 'transcript',
    },
    answered_by: {
      type: 'text/plain',
      description: 'Whether the call was answered by a person or a voicemail.',
      name: 'Answered By',
      isOptional: false,
      conditions: [
        {
          reference: 'answered_by_enabled',
          type: 'equals',
          value: true,
        },
      ],
      listDepth: 0,
      value: null,
      key: 'answered_by',
    },
    recording_url: {
      type: 'text/plain',
      description: 'The URL of the recording of the call.',
      name: 'Recording URL',
      isOptional: false,
      listDepth: 0,
      value: null,
      key: 'recording_url',
    },
  },
} satisfies StepDefinition;
